import React from 'react'
import { Link } from 'react-router-dom'
import "../Modules/specnews.css";
import { forwardRef } from "react";

const SpecNews = forwardRef((props, ref) => {
  return (
    <>
    <center>
        <h2 className='text-success'>Specialty Centers</h2>
        <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br /><br />

        <section className='container'>
        <div id="demo" class="carousel slide" data-ride="carousel">

{/* <!-- Indicators --> */}
<ul class="carousel-indicators bg-dark">
  <li data-target="#demo" data-slide-to="0" class="active"></li>
  <li data-target="#demo" data-slide-to="1"></li>
  <li data-target="#demo" data-slide-to="2"></li>
</ul>

{/* <!-- The slideshow --> */}
<div class="carousel-inner">

  <div class="carousel-item active">
    <img class="d-block w-100 specimg" src="speciality1.png" alt="Neurocritical Care image"/>
    <div class="carousel-caption">
        <h3>Neurocritical Care</h3>
        <p className='d-none d-md-block'>The Neurocritical Care provides neurological and cerebal intensive care with the evidence based best practice</p>
  </div>
  </div>

  <div class="carousel-item">
    <img class="d-block w-100 specimg" src="speciality2.png" alt="Motility Center"/>
    <div class="carousel-caption text-dark">
        <h3>Gastrointestinal Motility Center</h3>
        <p class="font-weight-bold d-none d-md-block">Gastrointestinal Motility Center, offers a holistic range of services including consultation, diagnosis and treatment of functional disorders of the gastrointestinal system from the upper to the lower parts</p>
  </div>
  </div>

  <div class="carousel-item">
    <img className="d-block w-100 specimg" src="speciality3.png" alt="Urology Center"/>
    <div class="carousel-caption text-dark">
        <h3>Urology Center</h3>
        <p class="font-weight-bold d-none d-md-block">Focuses on non-invasive and minimally invasive treatments, so the best possible results with the minimum amount of discomfort could be given. </p>
  </div>
  </div>

</div>

 {/* Left and right controls */}
<a class="carousel-control-prev" href="#demo" data-slide="prev">
  <span class="carousel-control-prev-icon bg-dark"></span>
</a>
<a class="carousel-control-next" href="#demo" data-slide="next">
  <span class="carousel-control-next-icon bg-dark"></span>
</a>

</div>
        </section> <br />



        <section ref={ref}><br /><br /><br />
        <h2 className='text-dark'>Medical Tips</h2>
        <p style={{borderBottom:'4px solid grey',width:'110px'}}></p> <br /><br />

        <div className='container'>
            <div className='row'>

            <div className='col-md-4 my-2'>
                <div className='card wow animate__animated animate__pulse'>
                <img class="card-img-top" src="tip1.png" alt="Card image cap" height='200px'/>
                        <div className='card-body'>
                            <h5 class="card-title text-success">Proper Hydration.. </h5>
                            <p class="card-text" style={{textAlign:"start"}}>Water is essential for survival — without it, you can live for just a few days. Water allows your body to perform critical metabolic tasks and to maintain a healthy blood volume. It lubricates our joints, carries oxygen and nutrients to cells, keeps electrolytes in balance, helps kidneys and the liver remove waste, dissolves minerals and nutrients for our bodies to absorb and keeps body tissues moistened.  </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 my-2'>
                    <div className='card wow animate__animated animate__pulse'>
                    <img class="card-img-top" src="tip2.png" alt="Card image cap" height='200px'/>
                        <div className='card-body'>
                            <h5 class="card-title text-success">Excercise and Weight</h5>
                            <p class="card-text" style={{textAlign:'start'}}>Maintaining a healthy weight is good for everyone. Exercise is an important part of a healthy lifestyle, and is part of rehabilitation for chronic disease. Regular exercise strengthens the lungs to reduce shortness of breath. It also improves the heart and muscles, and can help you feel good about yourself. Being overweight puts you at risk for many diseases, including heart disease, diabetes, and high blood pressure. </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-4 my-2'>
                <div className='card wow animate__animated animate__pulse'>
                <img class="card-img-top" src="tip3.png" alt="Card image cap" height='200px'/>
                        <div className='card-body'>
                            <h5 class="card-title text-success">Air Pollution & Healthy Homes</h5>
                            <p class="card-text" style={{textAlign:"start"}}>The air we breathe can contain particles of ozone, pollution, smoke, chemicals,dust mites, mold and other items that can affect our health. Exposure to hazardous pollutants may have an immediate or a long-term effect on healthy individuals and those with heart and lung, and other health issues. Watch local air quality advisories to know when to stay indoors.Manage indoor air pollution by reducing use of harsh cleaning chemicals.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div> <br />
        </section>
    </center>
    </>
  )
});

export default SpecNews