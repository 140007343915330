import React from 'react'
import styles from "../Modules/personal.module.css"
import { forwardRef } from "react";
import { useEffect,useState } from 'react';
import axios from 'axios';

const Personal = forwardRef((props,ref) => {
  let personalEndpoint = 'https://asoguntola-backend-2.vercel.app/blog/getpersonals'
  const [personalMessage, setpersonalMessage] = useState([])

  useEffect(() => {
   axios.get(personalEndpoint).then((res)=>{
   setpersonalMessage(res.data.Personals)
   })
  }, [])
  return (
    <>
    
        <center>
        <section ref={ref}> <br /><br /><br />
            <h2>Personal</h2>
            <p style={{borderBottom:'2px solid grey',width:'150px'}}></p>
                <div><img className={styles.famImage} src="fam1.jpg" />
                <figcaption> <b> Prof A.S Oguntola</b></figcaption>
                </div> <br />
                <div className='container'>
                  <h4>ABRIDGED CITATION</h4>
                  <h5>BACKGROUND</h5>
                    <p className='packageSurgeryText'>
                    Born about 60 years ago at the AYEDE model village at the eastern suburb area of Ibadan to a family of peasant farmers.
                    </p>
                  <h5>EDUCATION</h5>
                    <p className='packageSurgeryText'>
                    He attended the Bishop Phillips academy IBADAN between 1977 and 1982, after having concluded his primary and secondary
                    modern education in 1977.
                    To the Glory of the Almighty, the village boy completed his training at the prestigious Univérsity College hospital Ibadan
                    in the year 1988 to become a medical doctor.
                    He later went to the university of ILORIN teaching hospital for a six year postgraduate training between 1993 and 1999.
                    </p>
                    <h5>CAREER</h5>
                    <p className='packageSurgeryText'>
                    He has since then been working as a Consultant general surgeon at the LAUTECH Teaching Hospitals both at Osogbo and Ogbomoso .
                      He has been involved in the training  of both undergraduate and postgraduate medical students in the past three decades.
                    He has worked as a surgeon in various hospitals in Nigeria and had a couple of years abroad, this mainly in the area of endocrine
                    and abdominal surgery. <br /> In the field of academia, he was employed as a lecturer 1 in the year 2000 at the department of surgery. <br />
                    He rose steadily to the rank of a professor in the year 2014  and has been involved in many research activities, this culminated
                    in publishing of above seventy journal articles so far.
                    He has served as head of department at various times . He was the director of clinical services and training at the then LAUTECH
                    Teaching hospital Osogbo between 2001 and 2005. <br />
                    A member of various clubs and society; these including The Capricorn 83 club Ibadan, Oluyole club LAUTECH amongst others. 
                    He is happily married with his pillar of support, the indefatigable jewel of inestimable value and blessed with four children.
                    </p>

                    <div>
                    {personalMessage.map((item,index)=>(
                      <div key={item._id}>
                        <h4>{item.title}</h4>
                        <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>{item.decription}</div>
                      </div>
                    ))}
                </div>
                </div>
                
        </section>
        </center>
    
    </>
  )
})

export default Personal