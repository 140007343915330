import React from 'react'
import styles from "../Modules/personal.module.css"
import { forwardRef } from "react";

const Family = forwardRef((props,ref) => {
  return (
    <>
    <center>
        <section ref={ref}> <br /><br /><br />
            <h2 className='text-success'>Family</h2>
            <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br />
            <div>
                <div><img className={styles.famImage} src="famcouple.jpg" />
                <figcaption> <b> Prof A.S Oguntola with his beautiful wife.</b></figcaption>
                </div> <br /><br />
                <div><img className={styles.famImage} src="fam3.jpg"/>
                <figcaption> <b> Prof A.S Oguntola's wife and his children.</b></figcaption>
                </div> <br /><br />
                <div><img className={styles.famImage} src="fam4.jpg" />
                <figcaption> <b> Prof A.S Oguntola's Extended family.</b></figcaption>
                </div> <br /><br />
                <div><img className={styles.famImage1} src="fam5.jpg" />
                <figcaption> <b> Prof A.S Oguntola's with his wife,first son and a family friend.</b></figcaption>
                </div>
            </div>
        </section>
    </center>
    </>
  )
})

export default Family