import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../Modules/navbar.css";
const Navbar = ({contactRef,selfRef,familyRef,socialRef,academicRef,healthRef,studentRef,aibramRef}) => {
  const contact = (e) => {
    e.preventDefault();
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const self = (e) => {
    e.preventDefault();
    selfRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const family = (e) => {
    e.preventDefault();
    familyRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const social = (e) => {
    e.preventDefault();
    socialRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const academic = (e) => {
    e.preventDefault();
    academicRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const health = (e) => {
    e.preventDefault();
    healthRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const student = (e) => {
    e.preventDefault();
    studentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const aibram = (e) => {
    e.preventDefault();
    aibramRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div class="container-fluid">
        <nav class="navbar navbar-expand-xl navbar-dark bg-dark">
          <a class="navbar-brand" href="#">
            <img
              src="logo.png"
              width="50"
              height="50"
              style={{ borderRadius: "1000px" }}
              alt=""
            />
            As.Oguntola
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <ul class="navbar-nav">
              <li class="nav-item active px-2">
                <a className="nav-link navhove" href="#">
                  Home <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="dropdown nav-item active px-2">
                <Link
                  class="dropdown-toggle nav-link navhove"
                  data-toggle="dropdown"
                  to="#"
                >
                  Personal <span class="caret"></span>
                </Link>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="#" onClick={self}>
                      &nbsp; Personal/Self
                    </Link>
                  </li>
                  <div class="dropdown-divider"></div>
                  <li>
                    <a class="dropdown-item" href="#" onClick={family}>
                      &nbsp; Family
                    </a>
                  </li>
                  <div class="dropdown-divider"></div>
                  <li>
                    <a class="dropdown-item" href="#" onClick={social}>
                      &nbsp; Socials
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item active px-2">
                <Link class="nav-link navhove" to="#" onClick={academic}>
                  Academic/Scholar
                </Link>
              </li>
              <li class="nav-item active px-2">
                <Link class="nav-link navhove" to="#" onClick={health}>
                  Health/Breast care group
                </Link>
              </li>
              <li class="nav-item active px-2">
                <Link class="nav-link navhove" to="#" onClick={student}>
                  Students/Training
                </Link>
              </li>
              <li class="nav-item active px-2">
                <a class="nav-link navhove" href="CV.doc" download='CV.doc'>
                  CV
                </a>
              </li>
              <li class="nav-item active px-2">
                <Link class="nav-link navhove" to="#" onClick={aibram}>
                  AIBRAM Medicals
                </Link>
              </li>
              <li class="nav-item active px-2">
                <Link onClick={contact} class="nav-link navhove" to="#">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
