import React, { useEffect, useState } from 'react'
import styles from '../../Modules/adminlandingpage.module.css'
import { Link,useNavigate } from 'react-router-dom'
import axios from 'axios'
const AdminLandinPage = () => {
  const navigate=useNavigate()
  const endpoint ='https://asoguntola-backend-2.vercel.app/auth/verifytoken'
  let token = localStorage.token
  const [blogsArray, setblogsArray] = useState([])
  useEffect(() => {
    verify()
    getBlogs()
  }, [])
  
  const getBlogs = async () =>{
    const URL = "https://asoguntola-backend-2.vercel.app/blog/getblogs"
    axios.get(URL).then((result)=>{
      console.log(result.data)
      setblogsArray(result.data.blogs)
    })
  }
  const verify=()=>{
    axios.get(endpoint,{
      headers:{
          "Authorization" : `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
      }
  }).then((res)=>{
      if(!res.data.status){
          localStorage.removeItem("token")
          navigate("/adminsignin")
      }
  })
  }

  const deleteblogs = async(index)=>{
    const deleteEnndpoint = "https://asoguntola-backend-2.vercel.app/blog/deleteblog"
      let fillteredArray = blogsArray.filter((item,ind)=>index===ind)
      let objdelete = {fillteredArray}
      await axios.post(deleteEnndpoint,objdelete).then((response)=>{
        console.log(response.data.message)
        getBlogs()
      })
  }
  return (
    <>
    <center>
    <div className={styles.body}>
     <section className={styles.section}>
       <div className={styles.header}>
         <h3>ADMIN'S DASHBOARD</h3>
         <Link to='/admin/adminaddpage'>
         <button className={styles.headerbutton}>
         <i class="fa-solid fa-plus"></i>
         </button>
         </Link>
       </div> <br />
       <div className='container'>
        <h5 style={{color: 'white'}}>
        This is the page in which you view or delete informations you have added to the landing page before.
        </h5></div>

       
          {
            blogsArray.map((blog,index)=>(
              <div key={blog._id}>
                <div className={styles.firstsectionone}>
         <div className={styles.firstsection}>
              <h3>Heading: {blog.title}</h3>
              <div>
                <h4>
            Details:
            </h4> {blog.decription}
           </div>
           <h3>Category: <span>{blog.selectedValue}</span></h3>
           <button onClick={()=>deleteblogs(index)} className={styles.deletebutton}>
           <i class="fa-solid fa-trash"></i>
           </button>
           </div>
       </div>
           </div>
            )
            ).reverse()
          } <br />
          <Link to='/'>
         <button style={{borderRadius:'10px',padding: '4px',fontWeight: 'bold'}}>Go to main Website</button>
         </Link>
         <br /> <br />
         <Link to='/admin/adminaddpage'>
         <button style={{borderRadius:'10px',padding: '4px',fontWeight: 'bold'}}>
         Add new details
         </button>
         </Link>
         <br /> <br />
     </section>
     </div>
     </center>
    </>
  )
}

export default AdminLandinPage;