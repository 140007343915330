import React from 'react'
import styles from "../Modules/health.module.css"
import { forwardRef } from "react";
import { useEffect,useState } from 'react';
import axios from 'axios';

const HealthBreast = forwardRef((props,ref) => {
  let healthEndpoint = 'https://asoguntola-backend-2.vercel.app/blog/health'
  const [healthMessage, sethealthMessage] = useState([])

  useEffect(() => {
   axios.get(healthEndpoint).then((res)=>{
    sethealthMessage(res.data.Health)
   })
  }, [])
  return (
    <>
      <center>
        <section className='container' ref={ref}>
        <br /><br /><br />
            <h2 className='text-success'>Health/Breast Care</h2>
            <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br />
            <div >
              <img className={styles.breastImg} src="breast.jpg" alt="" />
              <br /> <br /> <br />
              <img className={styles.breast2Img} src="breast1.jpg" alt="" />
              <br /><br />
              <img className={styles.breast2Img} src="breast2.jpg" alt="" />
              <br /><br />
              <img className={styles.breast2Img} src="breast3.jpg" alt="" />
              <br /><br />
            </div>
            <div>
                      {healthMessage.map((item,index)=>(
                        <div key={item._id}>
                        <div>
                       <img className={styles.breast3Img} src={item.productimage} alt="" />{" "}
                        </div>
                          <h4>{item.title}</h4>
                          <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>{item.decription}</div>
                        </div>
                      ))}
              </div>
        </section>
        </center>
    </>
  )
})

export default HealthBreast