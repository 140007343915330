import React from 'react'
import styles from '../../Modules/adminlandingpage.module.css'
import { Link ,useNavigate} from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
const AdminAddPage = () => {
  const navigate=useNavigate()
  const [productimage, setproductimage] = useState("")
  const [title, settitle] = useState("")
  const [decription, setdecription] = useState("")
  const [file, setfile] = useState("")
  const [day, setday] = useState("")
  const [year, setyear] = useState("")
  const [hour, sethour] = useState("")
  const [minutes, setminutes] = useState("")
  const [month, setmonth] = useState("")
  const [message, setmessage] = useState('')
  const [loading, setloading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');
  const date = new Date()
  const hournow = date.getHours()
  const minutesnow = date.getMinutes()
  const daynow = date.getDay()
  const yearnow = date.getFullYear()
  const monthnow = date.getMonth() + 1
  let valueNNow 
  let token = localStorage.token
  const endpoint ='https://asoguntola-backend-2.vercel.app/auth/verifytoken'
  // console.log(hournow,minutesnow,daynow,yearnow,monthnow)
  useEffect(() => {
    verify()
  }, [selectedValue])
  
  const verify=()=>{
    axios.get(endpoint,{
      headers:{
          "Authorization" : `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
      }
  }).then((res)=>{
      if(!res.data.status){
          localStorage.removeItem("token")
          navigate("/adminsignin")
      }
  })
  }
  const handleSelectChange = (event) => {
     valueNNow = event.target.value
    setSelectedValue(event.target.value);
    console.log(valueNNow)
  }

  const filechange=(e)=>{
    let myImage = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(myImage)
    reader.onload = ()=>{
        setproductimage(reader.result)
    }
  }
  const add = async()=>{
    setloading(true)
    setmessage('')
    const url = 'https://asoguntola-backend-2.vercel.app/blog/addnewblogs'
    // const url = 'http://localhost:5000/blog/addnewblogs'
    let currentDate = {hournow, minutesnow,monthnow,yearnow}
    let newBlog = {productimage,title,decription,selectedValue,currentDate}
    await axios.post(url,newBlog).then((res)=>{
      setloading(false)
      setmessage(res.data.message)
        console.log(res.data)
    })

    
  }
  return (
    <>
    <center>
    <div className={styles.body}>
      <section className={styles.section}>
      <div className={styles.header}>
         <h3>ADDITION PAGE</h3>
         <Link to='/admin/adminpage'>
         <button className={styles.headerbutton}>View</button>
         </Link>
       </div>

       <div className='container' style={{color: 'white'}}>
        <br />
        <h3>Add Item</h3>
        <select name="categories" id="categories" value={selectedValue} onChange={handleSelectChange} style={{fontWeight: 'bold',height: '35px'}}>
          <option value="select an option">Select a Sub-Section</option>
          <option value="Academic/Scholar">Academic/Scholar</option>
          <option value="Personal">Personal</option>
          <option value="Student/Training">Student/Training</option>
          <option value="Health/health education/ breast care group">Health/health education/ breast care group</option>
          <option value="AIBRAM medicals">AIBRAM medicals</option>
         </select> <br /><br />
         <input type="text" placeholder='Title(Not mandatory)' className={styles.input} onChange={(e)=>settitle(e.target.value)}/> <br />
         <input type="file" className={styles.input} onChange={(e)=>filechange(e)}/> <br />
         <div style={{marginTop:'-15px',fontSize: '90%'}}> (choose a file incase of adding an image..Not mandatory)</div> <br />
         <textarea name="description" id="description" placeholder='Details/Information Text' className={styles.textarea} onChange={(e)=>setdecription(e.target.value)}></textarea> <br /><br />
         {loading ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={styles.messageText}>{message}</div>
         <button className={styles.submitbutton} onClick={add}><i class="fa-solid fa-plus"></i></button> <br /><br />
        
         <Link to='/admin/adminpage'>
         <button style={{borderRadius:'10px',padding: '4px',fontWeight: 'bold'}}>View added Infos</button>
         </Link> <br /><br />
         <Link to='/'>
         <button style={{borderRadius:'10px',padding: '4px',fontWeight: 'bold'}}>Go to main Website</button>
         </Link>
         <br /><br />
       </div>
      </section>
    </div>
    </center>
    </>
  )
}

export default AdminAddPage;