import React from 'react'
import { forwardRef } from "react";
import { useEffect,useState } from 'react';
import axios from 'axios';
import styles from "../Modules/personal.module.css"

const Aibram = forwardRef((props,ref) => {
  let aibramEndpoint = 'https://asoguntola-backend-2.vercel.app/blog/getairbram'
  const [aibramMessage, setaibramMessage] = useState([])

  useEffect(() => {
   axios.get(aibramEndpoint).then((res)=>{
    setaibramMessage(res.data.Airbram)
   })
  }, [])
  return (
    <>
    <center>
      <section className='container' ref={ref}>
      <br /><br /><br />
              <h2 className='text-success'>AIBRAM Medicals</h2>
              <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br />
              <div>
                      {aibramMessage.map((item,index)=>(
                        <div key={item._id}>
                        <div>
                       <img className={styles.famImage} src={item.productimage} alt="" />{" "}
                        </div>
                          <h4>{item.title}</h4>
                          <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>{item.decription}</div>
                        </div>
                      ))}
              </div>
      </section>
    </center>
    </>
  )
})

export default Aibram