import React from 'react'
import { forwardRef } from "react";
import { useEffect,useState } from 'react';
import axios from 'axios';

const Academic = forwardRef((props,ref) => {
  let academicEndpoint = 'https://asoguntola-backend-2.vercel.app/blog/getacademics'
  const [academicMessage, setacademicMessage] = useState([])

  useEffect(() => {
   axios.get(academicEndpoint).then((res)=>{
    setacademicMessage(res.data.Academics)
   })
  }, [])
  

  return (
    <>
      <center>
        <section className='container' ref={ref}>
        <br /><br /><br />
            <h2 className='text-success'>Academic/Scholar</h2>
            <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br />
            <div >
              <h4>PUBLISHED ARTICLES</h4>
                  <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>
                   (a) Journal articles published <br />
                  1.)&nbsp; Aderounmu A. O. A., Adeoti M. L, Oguntola A. S., Fadiora S. O., Ogundiran O. O. 	(2003). Lupus vulgaris – manifestation of extrapulmonary tuberculosis. Nigerian Journal 	of Surgical Science. 1: 7-9. Nigeria. <br />
                  2.)&nbsp; Adeoti M. L., Adisa S., Oguntola A. S., Fadiora S. O., Aderounmu A. O. A. (2003).  	Recurrent typhoid ileal perforation after 2 months – a case report. Nigerian Clinical 	Review. 7(4): 18-19. Nigeria <br />
                  3.)&nbsp; Fadiora S. O., Oluwadiya K. S., Kolawole I. K., Aderounmu A.O.A., Oguntola A. S., 	Adejumobi M. O. (2003). Audit of day-case surgery in LAUTECH Teaching Hospital, 	Osogbo, Nigeria. Nigerian Journal of Surgical Research. 5(1-2): 43-49. Nigeria <br />
                  4.)&nbsp; Fadiora S. O., Oguntola A. S., Aderounmu A. O. A., Bello T., Adeoti M. L., Awe O. O. 	(2003). Abdominal Wall Hernias as seen in LAUTECH Teaching Hospital Osogbo, Osun 	State, Nigeria.  Sahel Medical Journal. 6(2): 44 - 48. Nigeria <br />
                5.)&nbsp; Adeoti M. L., Fadiora S. O., Oguntola A. S., Aderounmu A. O. A., Laosebikan D. A., Adejumobi M. O. (2004).  Cryptorchidism in a local population in Nigeria. West African Journal of Medicine. 23(1): 62 - 64. WEST AFRICA. SCImago Journal Rank - H index of 18 <br />
                  6.)&nbsp; Fadiora S. O., Oluwadiya K. S., Aderounmu A. O. A., Oguntola A. S., Kolawole I. K., 	Isawumi I. A. (2004). Spinal (Subarachnoid) anaesthesia in the hands of non-physician 	anaesthetists: a prospective study of 131 cases. Nigerian Journal of Surgery.  10(1): 11-14. 	Nigeria. Index Copernicus Value of 3.44 <br />
                  7. Fadiora S. O., Oguntola A. S., Adeoti M. L. (2005). Risk of intra-operative facial blood 	splash. Nigerian Journal of Surgical Science. 15 (1): 7-12. Nigeria. H Index 2.2 <br />
                8.)&nbsp; Fadiora S. O., Mabayoje V. O., Olatoke S. A., Adeoti M. L., Aderounmu A. O. A., Oguntola A. S. (2005). Generalised Burkitt’s Lymphoma involving both breasts – a case report. West African Journal of Medicine. 24(3): 280-282. WEST AFRICA. SCImago Journal      Rank 	- H index of 18 <br />
                  9.)&nbsp; Olaitan P. B., Ojemakinde K. O., Oguntola A. S., Olaitan J. O. (2005).  Subcutaneous 	Phycomycosis presenting as an anterior chest and abdominal wall shield. A case report 	and literature review.  Nigerian Journal of Plastic Surgery. 1(1): 28-31. Nigeria <br />
                  10.)&nbsp; Oguntola A. S., Adeoti M. L., Ojemakinde K. O., Agodirin S. O. (2006). Lipoma 	mimicking Mickulicz’s disease: A case presentation. Nigerian Clinical Review. 10(3): 14-	15. Nigeria. <br />
                  11.)&nbsp; Aderounmu A. O. A., Adeoti M. L., Oguntola A. S., Oboro V. O., Fadiora S. O., 	Akanbi O. O., Bello T. O. (2006). Pattern and outcome of emergency surgery in a new 	Nigerian Teaching Hospital – The LAUTECH Teaching Hospital Experience. Nigerian 	Postgraduate Medical Journal. 13(3): 172-175. Nigeria. SCImago Journal Rank - H index 	of 11 <br />
                  12.)&nbsp; Aderounmu A. O. A., Egbewale E. B., Ojofeitimi E. O., Oguntola A. S., Fadiora S. 	O., Asekun-Olarinmoye E. O., Adeoti M. L., Akanbi O. A. (2006). Knowledge, attitude 	and practices of the educated and non-educated women to cancer of the breast in semi-	urban areas of South-Western Nigeria. Nigerian Postgraduate Medical Journal. 13(3): 	182-188. Nigeria. SCImago Journal Rank - H index of 11 <br />
                  13.)&nbsp; Aderounmu A. O. A., Oguntola A. S., Lawal O. O., Eziyi A. K., Bello T. O., Osinaike 	B. B., Agodirin S. O. (2007). Mirrizi syndrome caused by a bile pseudocyst. West African 	Journal of Medicine. 26(2): 148-151. WEST AFRICA. SCImago Journal Rank -H index of 18 <br />
                  14.)&nbsp; Ojo E. O., Oguntola A. S., Misauno M. A., Obiano S. K. (2007). Anorectal abscesses: 	a review of presentation and management. Nigeria. Sahel Medical Journal. 10(3): 72-78. <br />
                  15.)&nbsp; Adeoti M. L., Oguntola A. S., Aderounmu A. O. A., Agodirin S. O. (2008). Influence 	of socio-cultural, political, economic status and environment on the outcome of surgical 	practice in a developing tropical country – using Breast Cancer as case study. Surgery 	Journal. 3(2): 21-23. Pakistan. <br />
                16.)&nbsp; Oguntola A. S., Aderounmu A. O. A., Adeoti M. L., Olatoke S. A., Bello R. O., 	Rabiu T. B. (2008). Splenic operations in a teaching hospital, South-Western Nigeria. 	Nigeria. Sahel Medical Journal. 11(1): 4-8. <br />
                  17.)&nbsp; Adeoti M.L., Oguntola A. S., Olatoke S. A., Adejumobi M. O. (2008). Prevalence of 	Abdominal Wound Dehiscence following emergency laparatomy in LAUTECH Teaching 	Hospital Osogbo. Nigerian Clinical Review. 12(4): 20 - 23. Nigeria.<br />
                  18.)&nbsp; Oguntola A. S., Agodirin S. O., Adeleke B. A., Adeoti M. L., Olatoke A. S. (2008).  	Cancellation of elective surgical cases in a Teaching Hospital, South-Western Nigeria. 	Tropical Journal of Health Sciences. 16(1): 39-43. Nigeria<br />
                  19.)&nbsp; Akere A., Oguntola A. S., Ojemakinde K. O. (2008). Gastro-intestinal stromal tumour 	– case report and a review of the literature. Annals of Ibadan Postgraduate Medicine. 6(2): 	63 – 65.<br />
                  20.)&nbsp; Oguntola A. S., Aderounmu A. O. A., Adeoti M. L., Olatoke S. A., Akanbi O., 	Agodirin S. O. (2009). Male breast cancer in LAUTECH Teaching Hospital, Osogbo, 	South-Western Nigeria. Nigerian Postgraduate Medical Journal. 16(2): 166-170. Nigeria. 	SCImago Journal Rank - H index of 11.<br />
                  21.)&nbsp; Olaosun A. O., Oguntola A. S., Adegbosin O., Akinloye A. (2009). Mirror 	Laryngoscopy: a review of 43 cases.  Surgery Journal. 4(2): 29-34. Pakistan. <br />  
                22.)&nbsp; Oguntola A. S., Adeoti M. L., Bello T. O., Ojemakinde K. O., Mabayoje V. O. (2009). Surgical relief of obstructive jaundice and persistent epigastric pain in patient with huge retroperitoneal tumour: a case presentation . Western Nigerian Journal of Medical  Sciences. 2: 57-59.<br />
                  23.)&nbsp; Adeoti M. L., Oguntola A. S., Agodirin S. O., Idris L., Agbakwuru E. A. (2009). 	Pattern of referrals for oesophago-gastro-duodenoscopy and spectrum of findings in 	Osogbo. Nigerian Journal of Gastroenterology and Hepatology. 1(2): 83 - 88<br />
                  24.)&nbsp; Oguntola A. S., Agodirin S. O., Adeoti M. L., Aderounmu A. O. A. (2010). Bilateral 	breast cancer: experience in a poor resource black African setting. East and Central 	African Journal of Surgery. 15(1): 28-34. (Kenya, H index 4.4)<br />
                  25.)&nbsp; Oguntola A. S., Akanbi O. O., Adeoti M. L., Ojemakinde K. O. (2010). Clinical 	spectrum of benign breast diseases in Osogbo, Nigeria. Tropical Journal of Health 	Sciences. 17(2); 19-22. Nigeria. <br />
                  26.)&nbsp; Oguntola A. S., Adeoti M. L., Oyemolade T. A. (2010). Appendicitis: trends in 	incidence, age, sex and seasonal variations in south-western Nigeria. Annals of African 	Medicine. 9(4): 213 - 217. India. SCImago Journal Rank - H index of 8.<br />
                  27.)&nbsp; Oguntola A. S., Adeoti M. L., Akanbi O. O. (2011). Non-adherence to the use of 	Tamoxifen in the first year by the breast cancer patients in an African population. East and 	Central African Journal of Surgery. 16(1): 52-56. (Kenya, H index 4.4)<br />
                  28.)&nbsp; Olugbenga-Bello A., Oladele E. A., Bello T. O.,  Ojo J. O., Oguntola A. S. (2011).  	Awareness and Breast Cancer Risk Factors: Perception and Screening Practices among 	Females in a Tertiary Institution in Southwest Nigeria. Nigerian Postgraduate Medical 	Journal. 18(1): 8-15. Nigeria. SCImago Journal Rank - H index of 11.<br />
                  29.)&nbsp; Adeoti Moses, Agodirin Olayide, Odu Olusola, Oguntola Adetunji, Bello Temitope, 	Aderounmu Atilola. (2011). The role of men in early detection of their spouses breast 	lump(s)/ cancer. Nigerian Journal of General Practice. 9(2): 38 – 41.<br />
                  30.)&nbsp; Bello T. O., Olugbenga-Bello A. I., Oguntola A. S., Adeoti M. L., Ojemakinde O.M. 	(2011). Knowledge and Practice of Breast Cancer Screening Among Female Nurses and 	Lay Women in Osogbo, Nigeria. West African Journal of Medicine. 30(4): 296 - 300. 	WEST AFRICA. SCImago Journal Rank - H index of 18  <br />

                  31.)&nbsp; Agodirin Sulaiman O., Ojemakinde Oluyemisi M., Bello Temitope O., Oguntola 	Adetunji S., Aremu Ademola A., Ojemakinde Kunle O., Adeoti Moses L., Agbakwuru 	Emmanuel A. (2012). Ultrasound-guided wire localization of lesions detected on 	screening mammography in Osogbo, Nigeria and its impact on breast conservative 	surgery. Annals of African Medicine. 11 (2); 92 – 96. Nigeria. SCImago Journal Rank - H 	index of 8 & ICV of 6.03 <br />
                  32.)&nbsp; Bello T. O., Ojemakinde O. M., Oguntola A. S., Aremu A. A., Ojemakinde K. O., 	Agodirin S. O. (2012). Screening mammography features in Nigerian women: A pilot 	study. African Journal of Medicine and Medical Sciences. 41: (Accepted: 07/12/11). 	Nigeria. SCImago Journal Rank - H index of 15.<br />
                  33.)&nbsp; Oguntola A. S., Agodirin S. O., Adejumobi M. O., Adeoti ML. (2012). Evaluation 	of preoperative blood ordering practice for mastectomy and thyroidectomy in a teaching 	hospital. Nigerian Medical Practitioners. 61(1-2); 10 - 14. Nigeria<br />

                  34.)&nbsp; Muhibi M. A., Adeyemo M. O. A., Oguntola S. A., Omisore A. O., Isamot H. A. 	(2012). Seroprevalence of HIV among faithful Muslims in Osun State, southwest Nigeria. 	Saudi Journal of Applied Haematology. 3(2): 71 - 74. Saudi Arabia. <br />

                  35.)&nbsp; Oguntola Adetunji Saliu., Olaitan Peter Babatunde., Omotoso Olutayo., Oseni 	Ganiyu Oyediran. (2012). Knowledge, attitude and practice of prophylactic mastectomy 	among patients and relations attending a surgical outpatient clinic. Pan African Medical 	Journal. 13: 20. Uganda. I CV of 6.23    <br />
                  36.)&nbsp; Oyedeji O. A., Aremu A. A., Oninla S. O., Oguntola A. S. (2012). Clinical 	diagnostic dilemmas in childhood abdominal tuberculosis; a report of two cases. Western 	Nigerian Journal of Medicine. 5: 38 – 44. Nigeria<br />

                  37.)&nbsp; Oguntola A. S., Akanni E. O., Adeoti M. L. (2013). Any association between 	ABO/Rh blood groups and breast cancer? Tropical Journal of Health Sciences. 20(1): 45 – 	48. Nigeria.<br />
                  38.)&nbsp; Agodirin Olayide., Oguntola Adetunji., Adeoti Moses., Agbakwuru Austin., 	Oluwadiya Kehinde., Olofinbiyi Babatunde. (2013). Preoperative pain treatment in acute 	abdomen in Osogbo, Nigeria: a randomized double-blind placebo-controlled study. 	International Journal of Emergency Medicine. 6: 3. United Kingdom. SCImago Journal 	Rank - H index of 7 <br />
                  39.)&nbsp; Akanni Emmanuel Olufemi., +Oguntola Adetunji Saliu.+, Adeoti Moses Layiwola., 	Agodirin Sulaiman Olayide. (2013). Haematological Parameters in Female Breast Cancer 	Patients in South Western Nigeria. International Journal of Medical and Health Sciences. 	2(4): 398 – 401. ICV (2012) of 3.72.<br />
                  40.)&nbsp; Oguntola A. S., Adeoti M. L., Agodirin S. O., Oremakinde A. A., Ojemakinde K. 	O. (2014). Further exploration during open appendicectomy, assessment of some common 	intraoperative findings. Pakistan Journal of Medical Sciences. 30(2): 316 - 321. Pakistan. 	SCImago Journal Rank - H index of 13.<br />
                  41.)&nbsp;  Oguntola A. S., Adeoti M. L., Agodirin S. O., Aderounmu A.O.A. (2014). The 	Clinical Relevance of Diarrhoea in Patients with Suspected Uncomplicated Appendicitis. 	East and Central African Journal of Surgery. 19(1): 94-98. (Kenya, H index 4.4)<br />
                  42.)&nbsp; Oparinde Dolapo P., Oguntola Adetunji S., Atiba Adeniran S., Ajose Olabamiji A, 	Adeoye Adekemi A. (2014). Reduced manganese, selenium and zinc in newly diagnosed 	breast cancer subjects in South-Western Nigeria. JMSCR. 2(2): 447 - 457. USA. Thomson 	Reuter Impact Factor 1.1147. jmscr.igmpublication.org<br />
                43.)&nbsp; Akanni E Olufemi., Mabayoje V Olatunji., Oguntola A Saliu., Adeoti M Layiwola.,  Adedeji A Lawrence., Oparinde D Pius. (2014). Soluble Transferrin Receptor Discriminates Iron Deficiency Anemia from Anemia of Chronic Disease in Leukemic and Breast Cancer Patients. American Journal of Biomedical Sciences. 6(1): 6-10. USA. ICV of 6.25<br />
                          
                44.)&nbsp; S O Agodirin, G A Rahman, S A Olatoke, ML Adeoti, SA Oguntola. (2014) The impact of failed communication on the treatment of cancer of the breast, A case series. Tropical journal of health Sciences. 24 (1) 28 - 31.<br />

                45.)&nbsp;    Ojo E O, Chirdan O O, Ajape A A, Agbo S, Oguntola  A S, Adejumo A A,  Babayo U D.(2014)    Post-graduate surgical training  in Nigeria: The trainees’ perspective. Niger. Med. J. 2014;55 (4):342-7.<br />

                46.)&nbsp; Donatus Sabageh, Adetunji S. Oguntola+, Adebanke M. Oguntola, Olanrewaju O. Bajowa . ( 2014) . Incidental detection of microfilariae in a lymph node aspirate: A case report. Nigerian medical Journal Vol. 55 ( 5) 438- 440 <br />

                47.)&nbsp; Oguntola  A S, Akanbi O, Adeoti M L ,Aderounmu A O A, Idris O L. Repeat Laparotomy  in Typhoid Intestinal Perforation Patients; Experience from a Developing Nation.  Sch. J. App. Med. Sci.2015;3 (1B):85-91.<br />

                48.)&nbsp;  Adeoti ML, Oguntola AS, Akanni EO, Agodirin OS, Oyeyemi GM. Trace elements; copper, zinc and selenium, in breast cancer afflicted female patients in LAUTECH Osogbo, Nigeria. Indian J Cancer 2015;52: 106-9.  India. SCImago Journal Rank - H index of 18. <br />
                  
                49.)&nbsp;  O L Idris, MO Adejumobi, OA Kolawole, A S Oguntola, OO Akanbi, KB Beyioku, OA Adedeji. Acute mechanical bowel obstruction among adults seen at Ladoke Akintola University of Technology Teaching Hospital in Nigeria. East and central African journal of Surgery 2015;20(1): 73 – 8 <br />

                50.)&nbsp;  Sabageh, D., Adeyemi, A. and Oguntola, A., 2015. Aggressive angiomyxoma presenting as a huge intra-abdominal mass in a pregnant Nigerian woman: a case report. Pan African Medical Journal, 21(197).<br />
                51 Akanbi, O.O., Oguntola, A.S.,  Adeoti, M.L., Aderounmu, A.O.A., Idris, O.L. and Abayomi, O.O,. Delayed presentation in breast cancer: a study among south western nigerian , International Journal of Current Research, 7, (8), <br />


                52.)&nbsp; Oseni Oyediran Ganiyu,Oguntola Adetunji Saliu+,Olaitan   Peter Babatunde . HIV, Hepatitis B & C viruses status of emergency surgical patients in LAUTECH Teaching Hospital Osogbo. Western Nigerian Journal of Medical Sciences. (Corresponding author)  (Accepted)<br />
                53.)&nbsp; Adetunji S Oguntola, Temitope O Bello, Jehad Alakabani.(2014)   A prospective cross-sectional study of the sensitivity of ultrasound in the diagnosis of acute appendicitis  . West African journal of Ultrasound ( 2016)  WAJUS 17(2)12-15. (Accepted 2014)<br />
                54.)&nbsp; Moses layiwola  Adeoti,  Adetunji Saliu Oguntola+, Sulaiman O Agodirin,  Olusola .O Akanbi, A F  Omolade, AOA Aderounmu. (2016) Diagnostic yield of digital rectal examination during routine physical medical examination in a sub- urban African population. International Journal of Developmental Research.   2016.6(6) : 8212- 8215  .  India.  Impact Factor 4.25    http:/www.journalijdr.com.<br /> 
                55.)&nbsp; Akanbi Olusola Olateju,  Adeot i Moses Layi, Oguntola Adetunji Saliu+, Onilede David Adekunle, Aderounmu Adebimpe O. Atilola and Olawale Lateef  Idris . (2016) Audit of surgical operation notes in a teaching hospital, south western Nigeria: based on royal college o f surgeons of eng land as standard . International Journal of Development Research  . 6 (3); 7119 – 7121 . India.  Impact Factor 4.25    http:/www.journalijdr.com. <br />


                56.)&nbsp; Akanbi Olusola Olateju , Oguntola Adetunji  Saliu, Adeoti Moses Layi, Habeeb Olufemi Gbenga, Aderounmu AOA , oderinde GA.  (2016) Warfarin Toxicity: Unanticipated Cause of Mesenteric Haematoma, Massive Haemoperitoneum and Small Bowel Haemorrhagic Infarction. IOSR Journal of Dental and Medical Sciences (IOSR- JDMS) 15, Issue 4 (Apr. 2016), PP 88-90 . Thailand. ICV 2014  85.79  www.iosrjournals.org. DOI: 10.9790/0853<br />

                57.)&nbsp; Adeoti  Moses Layi, Akanbi  Olusola Olateju,  Oguntola Adetunji Saliu,  Habeeb  Olufemi Gbenga, Olamoyegun  Michael  Adeyemi, and  Kareem  Lukuman  O.(2016) Recurrent hypoglycaemic coma attack in patient with advanced gastric cancer: a rare  ocurrence. Sch J Med Case Rep 2015; 3(12):1172-1174. India.  Index Copernicus (IC Value 4.67) DOI:10.21276/sjmcr<br />

                58.)&nbsp;  Adeoti ML, Oguntola AS, Olugbenga-Bello AI, Oladimeji OJ, Jegede  SO . (2016)  Colorectal Cancer: Knowledge and Risk Factors among Adults in Suburban Nigerian Community  .  Journal of Medical Sciences And Clinical Research 4(9) 12478-12491 India. Impact factor 5.244,   ICV 83.27.  www.jmscr.igmpublication.org<br />

                59.)&nbsp;  Adeoti Moses Layiwola, Akanbi Olusola Olateju, Oguntola Adetunji Saliu, Onilede David Adekunle. (2016)  Knowledge and Awareness of HIV/AIDS prevention methods among patients attending surgical out- patient clinics in sub-saharan Africa. Saudi Journal of Medical and Pharmaceutical Sciences 2(9) 241-246.     DOI;10.21276/SJMPS.2016.2.9.3. United Arab Emirate. Impact Factor<br />
                60.)&nbsp;  Akanbi OO, Oyewole A O,  Oguntola AS, Ajiboye A O,  Adeoti ML, Amole IO, Olalere SA. (2016) Multidimensional health locus of control and qualitative study of goitre patients consulting traditional healers prior to orthodox doctors.  International Journal Of current Research; . 8 ( 06).32597-32601. India.  SJIF 5.349  . http://www.journalcra.com<br />
                61.)&nbsp;  Agodirin S Olayide,  Akande J Halimat, Olatoke A Samuel, Rahman A Ganiyu  ,Oguntola A Soliu. 2017 . Level of Awareness and Knowledge of Breast Cancer in Nigeria. A Systematic Review .Ethiop J Health Sci 2017;27(1):163 -74.. doihttp://dx.doi.org/10.4314/ejhs.v27i2.9<br />

                62.)&nbsp; Agodirin O , Olatoke S , Rahman G  , Kolawole O3 , Oguntola S  , Olasehinde O  , Ayandipo O  , Olaogun  J  , Katung A8 , Etonyeaku C  , Habeeb O  , Adeyeye A1 , Agboola J  , Akande H1 , Akanbi O4 , Abiyere H  , Fatudimu O. , Dec 2017 Delay between Breast Cancer Detection and Arrival at Specialist Clinic Preliminary Revelations of Multicentered Survey in Nigeria.  . Texila International Journal of Public Health Volume 5, Issue 4<br />

                63.)&nbsp; Olayide Agodirin , Samuel Olatoke,  Ganiyu Rahman, Julius Olaogun, Oladapo Kolawole,  John Agboola,  Olalekan Olasehinde,  Aba Katung,  Omobolaji Ayandipo,  Amarachukwu Etonyeaku,  Anthony Ajiboye,  Soliu Oguntola, and Oluwafemi  Fatudimu.  Impact of Primary Care Delay on Progression of Breast Cancer in a Black African Population: A Multicentered Survey. ournal of Cancer Epidemiology Volume 2019, Article ID 2407138, 10 pages https://doi.org/10.1155/2019/2407138<br />

                64.)&nbsp;  Olayide Agodirin , Samuel Olatoke , Ganiyu  Rahman , Julius Olaogun , Olalekan Olasehinde , Aba Katung , Oladapo Kolawole  , Omobolaji Ayandipo , Amarachukwu Etonyeaku , Olufemi Habeeb , Ademola Adeyeye , John Agboola, Halimat Akande, Soliu  Oguntola, Olusola Akanbi and Oluwafemi FatudimU. Presentation intervals and the impact of delay on breast cancer progression in a black African population.  l BMC Public Health (2020) 20:962 https://doi.org/10.1186/s12889-020-09074-w<br />

                65)&nbsp;  Olayide S. Agodirin, Isiaka Aremu,  Ganiyu A. Rahman,  Samuel A. Olatoke,  Halimat J. Akande,  Adetunji S. Oguntola,  Olalekan  Olasehinde,  Sheriff Ojulari, Amarachukwu Etonyeaku, Julius Olaogun and Anya Romanoff.  . Prevalence of Themes Linked to Delayed Presentation of Breast Cancer in Africa: A Meta-Analysis of Patient-Reported Studies. JCO Global  Oncol 6:731-742. © 2020 by American Society of Clinical Oncology.<br />

                66.)&nbsp;  Adedayo AA, Onigbinde O M, Oguntola AS, Idowu A N. Maiden paediatric laparascopic cholecystectomy in a Nigerian Teaching Hospital. Integrated Juournal of Medicine and Medical Sciences 2020. 1;1: 14 -18.<br />

                67.)&nbsp; Idowu NA, Ajamu O, Oguntola A S, Adeleye-Idowu S, Elijah A, Adekunle AA. Giant Benign prostatic hyperplasia in a 46-year-old man. Niger J Med 2021;30:720-3 ..<br />

                68.)&nbsp; Saliu Adetunji Oguntola, Olateju Olushola Akanbi, Tayewo Akinloye, Najeem Adedamola Idowu. Concurrent gastric outlet obstruction and perforation complicating chronic peptic ulcer disease. Integrated journal of medicine and Medical Sciences. 2022. 2; 1: 44 - 47
              </div>
              <div>
                {academicMessage.map((item,index)=>(
                  <div key={item._id}>
                      <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>{item.decription}</div>
                  </div>
                ))
                }
              </div>
            </div>
        </section>
      </center>
    </>
  )
})

export default Academic