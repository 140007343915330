import { buildQueries } from "@testing-library/react";
import { useState, useEffect } from "react";
import { Routes, Route} from "react-router";
import { Navigate } from "react-router";
import Navbar from "./Components/Navbar";
import LandingPage from "./Pages/LandingPage";
import SignIn from "./Pages/SignIn";
import AdminSignin from "./Pages/Admin/AdminSignin";

import Admin from "./Pages/Admin/Admin";

function App() {
 
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/signin" element={<SignIn/>} />
        <Route path="/adminsignin" element={<AdminSignin/>} />
        <Route path="/admin/*" element={<Admin/>}/>
        <Route path='/admin/*'  element={<Navigate to="/admin"/>}/>
      </Routes>
    </>
  );
}

export default App;
