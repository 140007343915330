import React from 'react'
import styles from "../Modules/personal.module.css"
import { forwardRef } from "react";

const Social = forwardRef((props, ref) => {
  return (
    <>
    <center>
    <section ref={ref}>
        <br /><br /><br />
            <h2 className='text-primary socialConflictMargin'>Socials</h2>
            <p style={{borderBottom:'2px solid grey',width:'150px'}}></p>
            <div> 
                <h5>Research Gate</h5>
                <p><a href="https://www.researchgate.net/profile/Adetunji-Saliu-Oguntola"><span><i style={{fontSize: '300%',marginTop: '13px',color: 'green'}} class="fa-brands fa-researchgate"></i></span></a></p>

                <h5>Google Scholar</h5>
                <p><a href="https://scholar.google.com/citations?user=MY52e9YAAAAJ&hl=en"><span><i style={{fontSize: '300%',marginTop: '13px',color: 'blue'}} class="fa-solid fa-graduation-cap"></i></span></a></p>
                <h5>Social media</h5>
                <span style={{marginRight: '4%'}}><a href="https://wa.me/+2349014288802"><span><i style={{fontSize: '350%',marginTop: '13px',color: 'green'}} class="fa-brands fa-whatsapp"></i></span></a></span>
                <span style={{marginRight: '4%'}}><a href="mailto:asoguntola@gmail.com"><span ><i style={{fontSize: '300%',marginTop: '13px',color: 'black'}} class="fa-solid fa-envelope"></i></span></a></span>
                <span ><a href="https://m.facebook.com/oguntola.adetunjisaliu"><span><i style={{fontSize: '300%',marginTop: '13px',color: 'blue'}} class="fa-brands fa-facebook"></i></span></a></span> <br /> <br />
            </div>
        </section>
    </center>
    </>
  )
})

export default Social