import React from 'react'
import { Routes, Route, Navigate} from "react-router";
import AdminLandinPage from "./AdminLandinPage";
import AdminAddPage from "./AdminAddPage";
const Admin = () => {
    let token = localStorage.token
  return (
    <>
      <Routes>
        <Route path="/adminpage" element={token?<AdminLandinPage/>:<Navigate to="/adminsignin"/>} />
        <Route path="/adminaddpage" element={token?<AdminAddPage/>:<Navigate to="/adminsignin"/>} />
      </Routes>
    </>
  )
}

export default Admin;