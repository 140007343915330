import React from 'react'
import styles from "../Modules/footer.module.css";
import { Link } from "react-router-dom";
import { forwardRef } from "react";

const Footer = forwardRef((props,ref) => {
  return (
    <>
    <center ref={ref}>
    <footer className={styles.footer}>
       <div className={styles.footerDisplay}>
            <div>
                <p className={styles.p}>Contact</p>
                <div className='text-white'>
                <i style={{fontSize: '120%',color: 'white'}} class="fa-solid fa-envelope"></i>  &nbsp;asoguntola@gmail.com <br />&nbsp;&nbsp;&nbsp;&nbsp; asoguntola@lautech.edu.ng <br /> 
                <i style={{fontSize: '120%',color: 'white'}} class="fa-brands fa-whatsapp"></i>  &nbsp;+234 901 428 8802 <br />
                <i style={{fontSize: '120%',color: 'white'}} class="fa-solid fa-phone"></i>  &nbsp;+234 803 381 5807 <br />
                </div> <br />
                <ul className={styles.ul}>
                <li className={styles.circledradius1}><a href="mailto:asoguntola@gmail.com"><span ><i style={{fontSize: '300%',marginTop: '13px',color: 'white'}} class="fa-solid fa-envelope"></i></span></a></li>
                <li className={styles.circledradius}><a href="https://wa.me/+2349014288802"><span><i style={{fontSize: '300%',marginTop: '13px',color: 'white'}} class="fa-brands fa-whatsapp"></i></span></a></li>
                <li className={styles.circledradius}><a href="https://m.facebook.com/oguntola.adetunjisaliu"><span><i style={{fontSize: '300%',marginTop: '13px',color: 'white'}} class="fa-brands fa-facebook"></i></span></a></li>
                </ul>
            </div>
            <div className={styles.locationtext}>
                <p className={styles.p}>Location</p>
                <i style={{fontSize: '120%',color: 'white'}} class="fa-solid fa-location-dot"></i> &nbsp; Address 1.<br />
                Alfatedo, Oke Owode. Ogbomosho, Oyo State.. <br />
                <i style={{fontSize: '120%',color: 'white'}} class="fa-solid fa-location-dot"></i> &nbsp; Address 2.<br />
                Road 5, Okebadan Estate, Akitiko behind Iyana Church. Iwo road, Ibadan.<br />
                Nigeria
            </div>
       </div>
        <div>© Copyright 2023 AIBRAM Association. All Rights Reserved</div>
    </footer>
    </center>
    </>
  )
})

export default Footer;