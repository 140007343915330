import React from "react";
import Navbar from "../Components/Navbar";
import styles from "../Modules/landingPage.module.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import TitleTop from "../Components/TitleTop";
import Packages from "../Components/Packages";
import SpecNews from "../Components/SpecNews";
import Footer from "../Components/Footer";
import { useRef } from "react";
import Personal from "../Components/Personal";
import Family from "../Components/Family";
import Social from "../Components/Social";
import Academic from "../Components/Academic";
import HealthBreast from "../Components/HealthBreast";
import Student from "../Components/Student";
import Aibram from "../Components/Aibram";


const LandingPage = () => {
  const contactRef = useRef(null);
  const medTipsRef = useRef(null);
  const selfRef = useRef(null);
  const familyRef = useRef(null);
  const socialRef = useRef(null);
  const academicRef =useRef(null);
  const healthRef = useRef(null);
  const studentRef = useRef(null);
  const aibramRef = useRef(null);

  return (
    <>
      <div>
        <div className="bg-dark fixed-top">
          <Navbar contactRef={contactRef} selfRef={selfRef} familyRef={familyRef} socialRef={socialRef} academicRef={academicRef} healthRef={healthRef}
           studentRef={studentRef} aibramRef={aibramRef}/>
        </div>
        <TitleTop medTipsRef={medTipsRef} /> <br />
        <br />
        <Packages />
        <SpecNews ref={medTipsRef} />
        <Personal ref={selfRef}/>
        <Family ref={familyRef}/>
        <Social ref={socialRef}/>
        <Academic ref={academicRef}/>
        <HealthBreast  ref={healthRef}/>
        <Student  ref={studentRef}/>
        <Aibram  ref={aibramRef}/>
         <br />
        <Footer ref={contactRef} />
      </div>
    </>
  );
};

export default LandingPage;
