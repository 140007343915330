import React from 'react'
import styles from "../Modules/titletop.module.css";


const TitleTop = ({medTipsRef}) => {
  const medTips = (e) => {
    e.preventDefault();
    medTipsRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
    <br /><br /><br />
    <div className='bg-dark' style={{position:'relative'}}>
        <img src="aso2.jpg" alt="building" className={styles.img} />
             <div style={{position:'absolute',top: '100px',left: '25px'}}>
             <p className={styles.titlefont} >
             PROF ADETUNJI SALIU OGUNTOLA <br />
             </p> <br />
             <div className={styles.subtitle}>
             
             Better Health? &nbsp;Better Tomorrow. <br /> Changing Health Care for Good. Stay healthy.
             
             <br />
             
              <button className={styles.greenbutton} onClick={medTips}>
                Medical Tips
              </button>  <br /><br />
             </div>
             </div>   
    </div>
    </>
  )
}

export default TitleTop