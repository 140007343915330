import React from 'react'
import { forwardRef } from "react";
import { useEffect,useState } from 'react';
import axios from 'axios';
import styles from "../Modules/personal.module.css"

const Student = forwardRef((props,ref) => {
  let studentEndpoint = 'https://asoguntola-backend-2.vercel.app/blog/getstudents'
  const [studentMessage, setstudentMessage] = useState([])

  useEffect(() => {
   axios.get(studentEndpoint).then((res)=>{
    setstudentMessage(res.data.Student)
   })
  }, [])
  return (
    <>
    <center>
      <section className='container' ref={ref}>
      <br /><br />
      <h3>Coming Soon</h3><br />
                <h2 className='text-success'>Students/Training</h2>
                <p style={{borderBottom:'2px solid grey',width:'150px'}}></p> <br />
                <div>
                      {studentMessage.map((item,index)=>(
                        <div key={item._id}>
                        <div>
                       <img className={styles.famImage} src={item.productimage} alt="" />{" "}
                        </div>
                          <h4>{item.title}</h4>
                          <div style={{textAlign: 'start',fontFamily: 'sans-serif',fontWeight: '500'}}>{item.decription}</div>
                        </div>
                      ))}
              </div>
      </section>
    </center>
    </>
  )
})

export default Student