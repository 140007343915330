import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

const Packages = () => {
    let navigate= useNavigate()
  return (
    <>
    <center>
        <br /><br />
        <div className='container wow animate__animated animate__zoomIn'>
            <div className='row'>

                <button className='col-md-6 border-0 bg-white'>
                <div>
                    <a href="https://wa.me/+2349014288802" className='card bg-dark text-white border-0 my-4'>
                    <div className='card-body bg-dark'>
                            <h1><i className='fa-solid fa-plus' style={{border:'2px solid white',borderRadius:'1000px',padding:'20px'}}></i></h1>
                            <h5 class="card-title">Send an Inquiry</h5> <br />
                            <p class="card-text">Ask about our Medical services.</p>
                        </div>
                     </a>
                </div>
                </button>
                <button className='col-md-6 border-0 bg-white'>
                    
                <div>
                    <a href="https://dokilink.com/pub/dr-saliu-adetunji-oguntola" className='card bg-primary text-white border-0 my-3'>
                        <div className='card-body bg-primary'>
                            <h1><i className='fa-solid fa-calendar-check' style={{border:'2px solid white',borderRadius:'1000px',padding:'20px'}}></i></h1>
                            <h5 class="card-title">Book Appointment</h5> <br />
                            <p class="card-text">Schedule your visit online.</p>
                        </div>
                    </a>
                </div>
                </button>

            </div>

        </div> <br /><br /><br /><br />

        <h2 className='text-success'>Surgery</h2>
        <p style={{borderBottom:'4px solid grey',width:'150px'}}></p> <br /><br />
        <section>
        <div className='container'>
            <div><img style={{height: '10%',width: '90%'}} src="operation.jpg" alt="" /></div> <br /><br />
            <p className='packageSurgeryText' style={{textAlign: "start"}}>Surgeries can be done to diagnose or treat disease or injury. In the operating room, 
                surgeons lead a team of other doctors and nurses to make sure that a procedure goes smoothly. <br />
                Open surgery requires a cut into the skin so the surgeon can see into the body.
                Minimally invasive surgery requires smaller entry points to make repairs and take tissue samples. It generally has 
                less recovery time than open surgery. <br /> But not all surgeries have a minimally-invasive option.
                There are different surgeons for each area of your body, and each uses a variety of techniques. <br />
                Surgery can be emergent or elective. Emergent surgeries are done for an urgent life-threatening condition,
                such as a serious injury. Elective surgeries can be scheduled in advance and don't need to be performed right away,
                although they aren’t always optional. <br />
                Surgery is a medical specialty that uses manual and/or instrumental techniques to physically reach into a subject's body in order to investigate or treat pathological conditions such as a disease or injury, to alter bodily functions (e.g. bariatric surgery such as gastric bypass), to improve appearance (cosmetic surgery), or to remove/replace unwanted tissues (body fat, glands, scars or skin tags) or foreign bodies. The subject receiving the surgery is typically a person (i.e. a patient), but can also be a non-human animal (i.e. veterinary surgery).
                </p>

        </div> <br /><br /> <br /><br />
        </section>
    </center>
    </>
  )
}

export default Packages